import logo1 from "@assets/partners/logo1.jpg";
import logo2 from "@assets/partners/logo2.jpg";
import logo3 from "@assets/partners/logo3.jpg";
import logo4 from "@assets/partners/logo4.jpg";
import logo5 from "@assets/partners/logo5.jpg";
import logo6 from "@assets/partners/logo6.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/react-splide/css";

export default function Partners() {
    const images = [logo1, logo2, logo3, logo4, logo5, logo6];
    const options = {
        type: "loop",
        gap: "10px",
        drag: "free",
        arrows: false,
        pagination: false,
        perPage: 6,
        autoScroll: {
            pauseOnHover: true,
            pauseOnFocus: false,
            rewind: false,
            speed: 2,
        },
    };
    return (
        <div className="w-screen md:h-[210px] h-fit py-2 flex flex-col justify-center items-center bg-gray-200">
            <Splide
                tag="section"
                className="md:h-[180px] h-[150px]"
                options={options}
                extensions={{ AutoScroll }}
            >
                {images.map((slide, index) => (
                    <SplideSlide key={index}>
                        <img src={slide} alt="..." />
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
}
